// Enable/Disable the utils that are being used

@use "../tools" as tools;

// =============================================================================
// inline
// =============================================================================
/**
 * Utility placed on a wrapping element to apply spacing on the left of each
 * underlying child element, except the first element.
 */
.u-inline {
	@include tools.generate-value(1, 2, 3, 4, 5, 7, 10, 16) using ($value) {
		& > * + * {
			margin-left: calc(#{var(--generic-4)} * #{$value});
		}
	}
}

/**
 * Utility placed on a wrapping element to apply spacing around each underlying
 * child element, except the first element. Used in combination with flex-wrap:
 * wrap.
 */
// .u-inline-wrap {
// 	@include tools.generate-value() using ($value) {
// 		$space: calc(#{var(--generic-4)} * #{$value});

// 		margin: $space * -1;

// 		& > * {
// 			margin: $space;
// 		}
// 	}
// }

// =============================================================================
// stack
// =============================================================================

/**
 * Utility placed on the wrapping element to apply spacing on the top of each
 * underlying child element, except the first element.
 */
.u-stack {
	@include tools.generate-value(2, 3, 4, 8, 10) using ($value) {
		& > * + * {
			margin-top: calc(#{var(--generic-4)} * #{$value});
		}
	}
}

// =============================================================================
// margin
// =============================================================================
.u-m {
	// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
	@include tools.generate-value(0, 1, 2, 4, 6) using ($value) {
		margin: calc(#{var(--generic-4)} * #{$value});
	}
}

.u-mt {
	// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
	@include tools.generate-value(-20, -2, 0, 1, 2, 3, 4, 5, 6, 8, 10, 12, 16, 18, 48, 58) using ($value) {
		margin-top: calc(#{var(--generic-4)} * #{$value});
	}
}

.u-mb {
	// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
	@include tools.generate-value(0, 1, 2, 3, 4, 6, 8, 10, 11, 12, 16, 20, 22, 25, 34, 40) using ($value) {
		margin-bottom: calc(#{var(--generic-2)} * #{$value});
	}
}

.u-ml {
	// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
	@include tools.generate-value(-3, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 49, 55, 70, 85) using ($value) {
		margin-left: calc(#{var(--generic-4)} * #{$value});
	}
}

.u-mr {
	// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
	@include tools.generate-value(-3, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 20) using ($value) {
		margin-right: calc(#{var(--generic-4)} * #{$value});
	}
}

// .u-mx {
// 	// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
// 	@include tools.generate-value() using ($value) {
// 		margin-right: calc(#{var(--generic-4)} * #{$value});
// 		margin-left: calc(#{var(--generic-4)} * #{$value});
// 	}
// }

.u-mx-auto {
	margin-right: auto;
	margin-left: auto;
}

.u-ml-auto {
	margin-left: auto;
}

.u-mt-auto {
	margin-top: auto;
}

.u-my {
	// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
	@include tools.generate-value(1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12) using ($value) {
		margin-top: calc(#{var(--generic-4)} * #{$value});
		margin-bottom: calc(#{var(--generic-4)} * #{$value});
	}
}

// =============================================================================
// padding
// =============================================================================
.u-p {
	// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
	@include tools.generate-value(1, 2, 3, 4, 6, 10, 11, 12, 16, 22) using ($value) {
		padding: calc(#{var(--generic-4)} * #{$value});
	}
}

.u-pt {
	// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
	@include tools.generate-value(1, 2, 3, 4, 5, 6, 8, 10, 12, 14, 16, 18, 20, 22, 25, 32) using ($value) {
		padding-top: calc(#{var(--generic-4)} * #{$value});
	}
}

.u-pb {
	// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
	@include tools.generate-value(4, 5, 6, 9, 11, 18, 20, 24, 42) using ($value) {
		padding-bottom: calc(#{var(--generic-4)} * #{$value});
	}
}

.u-pl {
	// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
	@include tools.generate-value(2, 4, 5, 6, 7, 8, 10, 13, 15, 22) using ($value) {
		padding-left: calc(#{var(--generic-4)} * #{$value});
	}
}

.u-pr {
	// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
	@include tools.generate-value(2, 6, 7, 8, 10, 13) using ($value) {
		padding-right: calc(#{var(--generic-4)} * #{$value});
	}
}

.u-px {
	// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
	@include tools.generate-value(2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 15, 16, 20, 24, 32) using ($value) {
		// Create different spacings in steps of 4
		$size: calc(#{var(--generic-4)} * #{$value});

		padding-right: $size;
		padding-left: $size;
	}
}

.u-py {
	// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
	@include tools.generate-value(1, 3, 6, 10, 20, 36, 42, 48, 64, 75, 96, 120, 128) using ($value) {
		padding-top: var(--generic-#{$value});
		padding-bottom: var(--generic-#{$value});
	}
}

.u-inset {
	width: calc(100% - #{var(--generic-8)} * 2);
	height: calc(100% - #{var(--generic-8)} * 2);
	overflow: hidden;
	margin: var(--generic-8);
}

.horizontal .product-grouping .u-inset,
.vertical .product-grouping .u-inset {
	width: 100%;
	height: 100%;
	margin: 0;
}

.horizontal .product-grouping .u-inset {
	height: calc(100% - 2px);
	width: calc(100% - 2px);
	margin: 1px 0 0 1px;
}
