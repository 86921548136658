@use "../tools" as tools;

// =============================================================================
// Block
// =============================================================================
.c-shoe-hero {
	position: relative;
	z-index: var(--z-base);

	width: 100%;
	height: 100%;
}

// =============================================================================
// Elements
// =============================================================================
.c-shoe-hero__heading {
	max-width: 34.38vw;
	margin-left: var(--generic-28);
}

.s-korean .c-shoe-hero__heading {
	padding-top: var(--generic-3);
}

.c-shoe-hero__content {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	margin-left: 3.2vw;
}

.vertical .c-shoe-hero__content {
	margin-left: 2.2vh;
}

.c-shoe-hero__bg {
	position: absolute;
	top: calc(#{var(--shoe-hero-space)} / 2);
	left: calc(#{var(--shoe-hero-space)} / 2);
	z-index: var(--z-background);

	width: calc(100% - #{var(--shoe-hero-space)});
	height: calc(100% - #{var(--shoe-hero-space)});
	overflow: hidden;
}

.vertical .c-shoe-hero__bg,
.horizontal .c-shoe-hero__bg {
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
}

.c-shoe-hero__bg-inner {
	position: relative;
	top: 50%;
	left: 50%;

	width: 120%;
	height: 120%;

	transform: translate3d(-50%, -50%, 0);
}

.c-shoe-hero__bg-ugc {
	width: 105%;
	height: 100%;
}

.vertical .c-shoe-hero__bg-ugc {
	width: 120%;
	height: 100%;
}

.c-shoe-hero__image-inner {
	position: relative;
	top: auto;
	bottom: 20%;
	left: 50%;

	width: 120%;
	height: 120%;

	transform: translate3d(-50%, 0, 0);
}

.c-shoe-hero__icon {
	margin-top: var(--generic-16);
	margin-left: var(--generic-16);

	color: var(--primary-contrast-color);
}

.c-shoe-hero__images {
	position: relative;

	width: 100%;
	height: 100%;
}

.c-shoe-hero__image-big {
	position: absolute;
	top: 0;
	right: 0;
	left: auto;

	width: calc(50% - var(--generic-16));
	max-width: var(--generic-480);
	height: calc(66.66% - var(--generic-16));
	overflow: hidden;
	margin: var(--generic-8);
}

.c-shoe-hero__image-small {
	position: absolute;
	top: auto;
	right: 50%;
	bottom: 0;
	left: auto;

	width: calc(25% - var(--generic-16));
	max-width: var(--generic-240);
	height: calc(33.33% - var(--generic-16));
	overflow: hidden;
	margin: var(--generic-8);
}

// =============================================================================
// Modifiers
// =============================================================================
.c-shoe-hero__bg-inner--small {
	width: 80%;
	height: 80%;
}

.c-shoe-hero__icon--small,
.c-shoe-hero__icon--big {
	position: absolute;
	top: 0;
	left: 0;
	z-index: var(--z-base);
}

.c-shoe-hero__image-small--max {
	transform: translateX(50%);
}
