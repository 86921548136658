@use "../tools" as tools;

// =============================================================================
// Block
// =============================================================================
.c-form {
	text-align: center;
}

// =============================================================================
// Elements
// =============================================================================
.c-form__fields {
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

.c-form__field {
	margin: #{var(--generic-8)} #{var(--generic-20)};

	text-align: left;
}

.c-form__label {
	display: block;
	margin-bottom: #{var(--generic-4)};

	font-weight: 600;
}

.c-form__input {
	min-width: #{var(--generic-350)};
	padding: #{var(--generic-8)};

	border: var(--border-base);
	outline: none;
}

.c-form__button {
	margin-top: #{var(--generic-16)};
}
