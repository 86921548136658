@use "../tools" as tools;

// =============================================================================
// Block
// =============================================================================
.c-countdown {
	--baseline: #{var(--generic-240)};

	position: relative;

	// Style the included SVG icon by directly selecting the SVG.
	& > svg {
		vertical-align: middle;
		width: var(--baseline);
		height: var(--baseline);

		stroke: var(--primary-base-color);
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-countdown__text {
	position: absolute;
	// Visually center text (because line-height is slightly off)
	top: 51%;
	left: 50%;

	transform: translate3d(-50%, -50%, 0);
}

.c-countdown__circle {
	stroke: var(--border-color);
}

.c-countdown__progress {
	stroke: var(--primary-base-color);
}
