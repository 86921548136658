@use "../tools" as tools;

// =============================================================================
// Block
// =============================================================================
.c-bottom-bar {
	position: absolute;
	bottom: 0;
	left: 0;

	height: var(--generic-60);
	padding-left: var(--generic-16);
}
