@use "../tools" as tools;

:root {
	// Borders
	--border-style: solid;
	--border-base: var(--border-width) var(--border-style) var(--border-color);
	// Radius
	--radius-max: 100vmax;
	--radius-round: 50%;
}
