// =============================================================================
// Block
// =============================================================================
.c-canvas-grid {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	// TODO: Remove when colors of the lines match the design
	opacity: 0.3;
}
