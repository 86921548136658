// =============================================================================
// Block
// =============================================================================
.c-slide {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;

	width: 100%;
	height: 100%;

	pointer-events: none;
}

.horizontal .compare .c-slide {
	top: 1px;

	height: calc(100% - 1px);
	width: calc(100% - 1px);
}

.horizontal .detail .c-slide {
	left: 1px;
	top: 1px;

	height: calc(100% - 2px);
	width: calc(100% - 2px);
}

.horizontal .compare .right .c-slide {
	left: 1px;
}

// =============================================================================
// Modifiers
// =============================================================================
.c-slide__drag {
	pointer-events: all;
}

// =============================================================================
// Modifiers
// =============================================================================
.c-slide--back {
	z-index: -1 !important; // stylelint-disable-line declaration-no-important
}

.c-slide--front {
	z-index: 10 !important; // stylelint-disable-line declaration-no-important
}
