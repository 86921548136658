@use "../tools" as tools;

// =============================================================================
// Block
// =============================================================================
.c-shoe-heading {
	display: flex;
	align-items: center;
}

.vertical .reviews .c-shoe-heading {
	height: 100%;
	padding-left: var(--generic-36);
}

// =============================================================================
// Elements
// =============================================================================
.c-shoe-heading__title-wrapper {
	flex: 1;
}

.c-shoe-heading__title {
	display: inline-block;
}

.s-korean .c-shoe-heading__title {
	padding-top: var(--generic-3);
}

.c-shoe-heading__image {
	width: var(--generic-142);
	min-width: var(--generic-142);
	height: var(--generic-60);
}

.c-shoe-heading__visual {
	object-position: 50% 60% !important; // stylelint-disable-line declaration-no-important
}

// =============================================================================
// States
// =============================================================================
.c-shoe-heading__title-wrapper {
	&.is-hidden {
		opacity: 0;
	}
}
