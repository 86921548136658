@use "../tools" as tools;

// =============================================================================
// Elements
// =============================================================================
.c-options__placeholder {
	// width of modal image + spacing of 7
	padding-left: var(--generic-408);
}

.vertical .c-options__placeholder {
	// width of modal image + spacing of 7
	padding: 0;
}

.vertical .options-button {
	position: absolute;
	bottom: 0;
	left: 0;
}

.vertical.options .c-modal__image {
	width: 8vh;
	height: auto;
}
