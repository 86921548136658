/**
 * CSS RESET based on:
 * - http://meyerweb.com/eric/tools/css/reset/
 * - https://github.com/hankchizljaw/modern-css-reset/blob/master/src/reset.css
 * - https://github.com/twbs/bootstrap/blob/main/scss/_reboot.scss
 */

// =============================================================================
// root
// =============================================================================
*,
*::before,
*::after {
	box-sizing: border-box;

	// This is added to prevent selection on touchscreens
	user-select: none;
}

html:focus-within {
	scroll-behavior: smooth;
}

/**
 * 1. Prevent adjustments of font size after orientation changes in iOS.
 * 2. Change the default tap highlight to be completely transparent in iOS.
 */
body {
	min-height: 100vh;

	line-height: 1.5;
	text-rendering: optimizeSpeed;

	// stylelint-disable-next-line property-no-vendor-prefix
	-webkit-text-size-adjust: 100%; // 1
}

// =============================================================================
// General
// =============================================================================
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	vertical-align: baseline;
	margin: 0;
	padding: 0;

	font: inherit;
	font-size: 100%;

	border: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

/**
 * 1. Always hide an element with the `hidden` HTML attribute.
 */
[hidden] {
	// stylelint-disable-next-line declaration-no-important
	display: none !important;
}

// =============================================================================
// Text
// =============================================================================
blockquote,
q {
	quotes: none;

	&::before,
	&::after {
		content: "";
		content: none;
	}
}

/**
 * 1. Add the correct text decoration in Chrome, Edge, Opera, and Safari.
 * 2. Add explicit cursor to indicate changed behavior.
 * 3. Prevent the text-decoration to be skipped.
 */
abbr[title] {
	text-decoration: underline dotted; // 1

	cursor: help; // 2
	text-decoration-skip-ink: none; // 3
}

address {
	font-style: normal;
	line-height: inherit;
}

/**
 * 1. Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
	position: relative; // 1

	vertical-align: baseline; // 1

	line-height: 0; // 1
}

// =============================================================================
// Table
// =============================================================================
/**
 * 1. Prevent double borders
 * 2. Remove default table styling
 * 3. Ensure tables fill up as much space of their respective containers.
 */
table {
	width: 100%; // 3

	caption-side: bottom; // 1
	border-collapse: collapse; // 1, 2
	border-spacing: 0; // 2
}

caption {
	text-align: left;
}

/**
 * 1. Matches default `<td>` alignment by inheriting `text-align`.
 * 2. Fix alignment for Safari
 */

th {
	text-align: inherit; // 1
	text-align: -webkit-match-parent; // 2
}

thead,
tbody,
tfoot,
tr,
td,
th {
	border-color: inherit;
	border-style: solid;
	border-width: 0;
}

// =============================================================================
// Media
// =============================================================================

/**
 * 1. Removes whitespace under the <img> elements when they are dropped into a
 *    page without any styling. Safer alternative than display: block;
 */

img,
svg {
	vertical-align: middle; // 1
}

/**
 * 1. Fluid images for responsiveness
 * 2. Differentiate alt text when image is not shown from surrounding copy.
 * 3. If a `width` and/or `height` attribute has been defined, don't make the
 *    image fluid.
 */

img {
	max-width: 100%; // 1

	font-style: italic; // 2

	&[width],
	&[height] {
		max-width: none; // 3
	}
}

// =============================================================================
// Form
// =============================================================================
// Inherit fonts for inputs and buttons
input,
textarea,
optgroup,
select {
	font: inherit;
}

// 1. Allow labels to use `margin` for spacing.
label,
output {
	display: inline-block; // 1
}

// 1. Remove the inheritance of text transform in Firefox
select {
	text-transform: none; // 1
}

// 1. Set the cursor for non- <button> buttons
[role="button"] {
	cursor: pointer; // 1
}

/**
 * 1. Remove the inheritance of word-wrap in Safari.
 * 2. Undo the opacity change from Chrome
 */
select {
	word-wrap: normal; // 1

	&:disabled {
		opacity: 1; // 2
	}
}

// 1. Add `pointer` cursor to non-disabled button elements.
[type="button"],
[type="reset"],
[type="submit"] {
	&:not(:disabled) {
		cursor: pointer; // 1
	}
}

/**
 * 1. Textareas should really only resize vertically so they don't break their
 *    (horizontal) containers.
 */
textarea {
	resize: vertical; // 1
}

/**
 * 1. Reset default `min-width: min-content;` on fieldsets in order to behave it
 *    more like a standard block element.
 * 2. Reset the default outline behavior
 */
fieldset {
	min-width: 0; // 1
	padding: 0; // 2

	border: 0; // 2
}

/**
 * 1. By using `float: left`, the legend will behave like a block element.
 * 2. Reset default margin.
 * 3. Fix wrapping bug.
 */

legend {
	float: left; // 1
	width: 100%;
	margin: 0; // 2

	+ * {
		clear: left; // 3
	}
}

/**
 * 1. Correct the outline style in Safari.
 */
[type="search"] {
	outline-offset: -2px; // 1
}

/**
 * 1. Change font properties to `inherit`
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
::-webkit-file-upload-button {
	font: inherit; // 1

	// stylelint-disable-next-line property-no-vendor-prefix
	-webkit-appearance: button; // 2
}

// =============================================================================
// Iframe
// =============================================================================
/**
 * 1. Remove border from iframe
 */
iframe {
	border: 0; // 1
}

// =============================================================================
// Summary
// =============================================================================
/**
 * 1. Add the correct display in all browsers
 */
summary {
	display: list-item; // 1

	cursor: pointer;
}

// =============================================================================
// Progress
// =============================================================================
/**
 * 1. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
	vertical-align: baseline; // 1
}

// =============================================================================
// List
// =============================================================================
/**
 * 1. Remove defaults on lists with class, indicates custom styling.
 */
ul[class],
ul[role="list"],
ol[class],
ol[class="list"] {
	list-style: none; // 1
}

/**
 * 1. Remove trailing margins from nested lists.
 */
li > {
	ol,
	ul {
		margin-bottom: 0; // 1
	}
}

// =============================================================================
// Link
// =============================================================================
/**
 * 1. A elements with class indicate custom styling
 * 2. A elements that don't have a class get default styles
 * 3. Undo the styles for placeholder links/named anchors (without href)
 */
a {
	display: inline-block;

	&[class] {
		text-decoration: none; // 1
	}

	&:not([class]) {
		text-decoration-skip-ink: auto; // 2
	}

	&:not([href]):not([class]) {
		// stylelint-disable-next-line selector-max-pseudo-class
		&,
		&:hover {
			text-decoration: none; // 3

			color: inherit; // 3
		}
	}
}

// =============================================================================
// Button
// =============================================================================
/**
 * 1. Remove default styling
 * 2. Force pointer cursor onto button
 * 3. Remove the default `border-radius` that macOS Chrome adds.
 * 4. Add `pointer` cursor to non-disabled button elements.
 * 5. Remove the inheritance of text transform in Firefox
 */
button {
	display: inline-block;
	padding: 0; // 1

	font: inherit;
	text-transform: none; // 5

	border-radius: 0; // 3
	border: none; // 1
	background-color: transparent; // 1

	cursor: pointer; // 2

	&:not(:disabled) {
		cursor: pointer; // 4
	}
}

// =============================================================================
// Hr
// =============================================================================
hr {
	margin: 0;

	background-color: currentColor;
	border: 0;

	&:not([size]) {
		height: 1px;
	}
}

// =============================================================================
// Code
// =============================================================================
pre,
code,
kbd,
samp {
	unicode-bidi: bidi-override;
}

/**
 * 1. Don't allow content to break outside
 * 2. Disable auto-hiding scrollbar in legacy Edge to avoid overlap, making it
 *    impossible to interact with the content
 */
pre {
	display: block;
	overflow: auto; // 1
	-ms-overflow-style: scrollbar; // 2

	// Take into account some code outputs that place code tags in pre tags
	code {
		word-break: normal;

		color: inherit;
	}
}

code {
	word-wrap: break-word;

	// Streamline style when inside anchors to avoid broken underline and more
	a > & {
		color: inherit;
	}
}

kbd {
	kbd {
		padding: 0;
	}
}

// =============================================================================
// Motion
// =============================================================================
// Remove all animations and transitions for people that prefer not to see them
@media (prefers-reduced-motion: reduce) {
	html:focus-within {
		scroll-behavior: auto;
	}

	*,
	*::before,
	*::after {
		// stylelint-disable declaration-no-important
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		transition-duration: 0.01ms !important;
		scroll-behavior: auto !important;
		// stylelint-enable declaration-no-important
	}
}
