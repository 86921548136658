@use "../tools" as tools;

// =============================================================================
// Block
// =============================================================================
.c-progress-bar {
	width: 100%;
	height: var(--generic-6);
	overflow: hidden;

	border-radius: var(--radius-max);
	background-color: var(--secondary-shade-color);
}

// =============================================================================
// Elements
// =============================================================================
.c-progress-bar__value {
	width: var(--generic-428);
	height: var(--generic-6);

	background: linear-gradient(190deg, var(--accent-2-shade-color) 16.52%, var(--accent-2-base-color) 98.63%);
	border-radius: var(--generic-10);
}

.vertical .c-progress-bar__value {
	width: var(--generic-445);
}
