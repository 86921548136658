@use "../tools" as tools;

// =============================================================================
// Block
// =============================================================================
.c-modal {
	position: fixed;
	top: 0;
	left: 0;
	z-index: var(--z-foreground);

	// Same as the display component
	width: 100vw;
	height: 56.25vw;
	overflow: hidden;
}

.vertical.c-modal {
	bottom: 0;
	top: unset;

	width: 56.25vh;
	height: 100vh;
}

// =============================================================================
// Elements
// =============================================================================
.c-modal__overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	background-color: var(--backdrop-color);
	backdrop-filter: blur(var(--generic-10));
}

.c-modal__content {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;

	background-color: var(--background-base-color);
}

.vertical .c-modal__content {
	background-color: var(--background-base-color);
}

.vertical .c-modal__content {
	height: 100%;
}

.vertical.options .c-modal__content {
	height: auto;
}

.c-modal__image {
	display: flex;
	align-items: center;
	width: var(--generic-380);
	height: var(--generic-210);
}

// =============================================================================
// Modifiers
// =============================================================================
.c-modal--fullscreen {
	.c-modal__content {
		background-color: var(--background-base-color);
	}

	.c-modal__overlay {
		background-color: var(--background-base-color);
	}
}

// .vertical.c-modal--fullscreen {
// 	.c-modal__content {
// 		background-color: var(--background-alt-color);
// 	}

// 	.c-modal__overlay {
// 		background-color: var(--background-alt-color);
// 	}
// }
