// =============================================================================
// Elements
// =============================================================================
.c-breadcrumb__item {
	padding: 0;

	text-decoration: none;

	color: var(--primary-base-color);
	border: none;
}
