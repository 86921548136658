// Enable/Disable the utils that are being used
@use "../tools" as tools;

.u-fill-width {
	width: 100%;
}

.u-fill-height {
	height: 100%;
}

.u-fill-space {
	width: 100%;
	height: 100%;
}

.u-float-right {
	float: right;
}

.u-fill-inset {
	width: calc(100% - var(--generic-8));
	height: calc(100% - var(--generic-8));
	margin: var(--generic-4);
}

.u-pos-relative {
	position: relative;
}

.u-max-width {
	@include tools.generate-value(36, 44, 54, 65, 70, 80, 90, 100, 110, 120, 130, 160, 200, 300) using ($value) {
		max-width: calc(#{var(--generic-4)} * #{$value});
	}
}

.u-width {
	@include tools.generate-value(7, 45, 48, 58, 90, 105, 180) using ($value) {
		width: calc(#{var(--generic-4)} * #{$value});
	}
}

.u-recommendation-width {
	@include tools.generate-value(1) using ($value) {
		width: var(--generic-26);
	}
}

.u-height {
	@include tools.generate-value(4, 11, 12, 24, 31, 58) using ($value) {
		height: calc(#{var(--generic-4)} * #{$value});
	}
}

.u-width-102\% {
	width: 102%;
}

.u-width-75\% {
	width: 75%;
}

.u-width-65\% {
	width: 65%;
}

.u-width-50\% {
	width: 50%;
}

.u-width-25\% {
	width: 25%;
}

.u-min-height {
	@include tools.generate-value(90) using ($value) {
		min-height: calc(#{var(--generic-4)} * #{$value});
	}
}

.u-max-height {
	@include tools.generate-value(150) using ($value) {
		max-height: calc(#{var(--generic-4)} * #{$value});
	}
}
