.u-hide\! {
	// stylelint-disable-next-line declaration-no-important
	display: none !important;
}

.u-pointer-events-all {
	// stylelint-disable-next-line declaration-no-important
	pointer-events: all !important;
}

.u-pointer-events-none {
	// stylelint-disable-next-line declaration-no-important
	pointer-events: none !important;
}
