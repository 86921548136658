@use "../tools" as tools;

// =============================================================================
// Block
// =============================================================================
.c-reload-button {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 100000;

	display: block;
	width: var(--generic-60);
	height: var(--generic-60);

	&:focus {
		outline: none;
	}
}
