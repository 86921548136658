@use "../tools" as tools;

:root {
	// font-family
	--font-f-primary: "FUTURA";
	--font-f-secondary: "NikeHelvetica";

	// font-weight
	--font-w-bold: 800;
	--font-w-medium: 600;
	--font-w-normal: 400;

	// line-height
	--line-height-default: 1.25;

	word-break: keep-all;

	// Class used to indicate korean.
	.s-korean {
		--font-f-primary: "Noto Sans KR", "FUTURA", sans-serif;
		--font-f-secondary: "Noto Sans KR", "NikeHelvetica", sans-serif;
	}

	.s-english {
		--font-f-primary: "FUTURA";
		--font-f-secondary: "NikeHelvetica";
	}

	.s-chinese {
		--font-f-primary: "Nike Futura CN", "FUTURA", sans-serif;
		--font-f-secondary: "Noto Sans SC", "NikeHelvetica", sans-serif;
	}
}
