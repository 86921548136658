@use "../tools" as tools;

// =============================================================================
// Block
// =============================================================================
.c-icon {
	--baseline: var(--generic-16);

	display: inline-flex;
	justify-content: center;
	align-items: center;
	vertical-align: middle;

	// Style the included SVG icon by directly selecting the SVG.
	& > svg {
		vertical-align: middle;
		width: var(--baseline);
		height: var(--baseline);
	}
}

// =============================================================================
// Modifiers
// =============================================================================
.c-icon--size {
	// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10
	@include tools.generate-value(3, 4, 5, 6, 7, 8, 9, 14) using ($item) {
		// Create different icon sizes in steps of 4
		$size: calc(#{var(--generic-4)} * #{$item});

		& > svg {
			--baseline: #{$size};
		}
	}
}

.c-icon-recommendation--size {
	// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10
	@include tools.generate-value(1) using ($item) {
		// Create different icon sizes in steps of 4
		$size: calc(#{var(--generic-26)} * #{$item});

		& > svg {
			--baseline: #{$size};
		}
	}
}

.c-icon-membership--size {
	// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10
	@include tools.generate-value(3, 6, 7, 8, 9, 10, 12, 13, 14, 18) using ($item) {
		// Create different icon sizes in steps of 4
		$size: calc(#{var(--generic-3)} * #{$item});

		& > svg {
			--baseline: #{$size};
		}
	}
}

.c-icon-membership--size-member {
	& > svg {
		--baseline: var(--generic-39);
	}
}

.c-icon--outline {
	display: flex;
	justify-content: center;
	align-items: center;
	width: var(--generic-24);
	height: var(--generic-24);

	border: var(--border-width) var(--border-style) var(--primary-contrast-color);
	border-radius: var(--radius-round);
	color: var(--primary-contrast-color);
}
