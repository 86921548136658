@use "../tools" as tools;

// =============================================================================
// Block
// =============================================================================
.c-button-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: var(--generic-25);
	height: var(--generic-25);

	text-decoration: none;

	border-radius: var(--radius-round);
	border: var(--border-width) var(--border-style) var(--primary-base-color);
	background-color: var(--primary-base-color);
	color: var(--primary-contrast-color);
}

// =============================================================================
// Modifiers
// =============================================================================
.c-button-icon--outline {
	background-color: transparent;
	border: var(--border-width) var(--border-style) var(--primary-base-color);
	color: var(--primary-base-color);
}

.c-button-icon--large {
	width: var(--generic-44);
	height: var(--generic-44);
}
