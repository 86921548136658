@use "../tools" as tools;

// =============================================================================
// Block
// =============================================================================
.c-block {
	position: relative;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	height: 100%;

	text-align: left;
}

.vertical .c-block {
	position: relative;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	width: calc(100% - 1px);
	height: calc(100% - 1px);

	text-align: left;
}

// =============================================================================
// Elements
// =============================================================================
.c-block__header,
.c-block__content,
.c-block__footer {
	z-index: var(--z-base);

	width: 100%;

	color: var(--primary-base-color);
}

.c-block__header {
	margin-bottom: var(--generic-32);
}

.c-block__footer {
	margin-top: var(--generic-32);
}

.favorite {
	.c-block__header {
		min-height: var(--generic-20);
		margin-bottom: var(--generic-30);
	}

	.c-block__footer {
		min-height: var(--generic-42);
		margin-top: var(--generic-9);
	}
}

.c-block__bg {
	position: absolute;
	top: calc(#{var(--block-space)} / 2);
	left: calc(#{var(--block-space)} / 2);

	width: calc(100% - #{var(--block-space)});
	height: calc(100% - #{var(--block-space)});
	overflow: hidden;

	&::after {
		content: "";

		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;

		background-color: var(--backdrop-color);
	}
}

.vertical .c-block__bg,
.horizontal .c-block__bg {
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
}

.horizontal .c-block__bg {
	height: calc(100% - 2px);
	width: calc(100% - 2px);
	margin: 1px 0 0 1px;
}

.vertical .c-block--base {
	height: calc(100% - 2px);
	width: calc(100% - 2px);
	margin: 0px 0 0 0px;
}

.c-block__bg.no-detail.detail-page img {
	position: relative;
	top: var(--generic--155);
}

.c-block__bg.no-detail.recommended img {
	top: 0;
}

.c-block__bg.no-detail.recommended.compare-page img {
	margin-top: var(--generic--75);
}

.c-block__content {
	flex: 1;
}

.c-block__content-inner {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
}

.c-block__footer {
	min-height: var(--generic-20);
}

.c-block__footer-inner {
	position: absolute;
	top: 0;
	left: 0;
}

// =============================================================================
// Modifiers
// =============================================================================
.c-block--floating-content {
	.c-block__content {
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;
	}

	.recommendation {
		top: auto;
	}
}

.c-block--white {
	background-color: white;
}

.c-block--base {
	background-color: var(--background-base-color);
}

.c-block--inset {
	padding: var(--block-space);
}

.c-block--inset-y {
	padding-top: var(--block-space);
	padding-bottom: var(--block-space);
}

.c-block--inset-x {
	padding-right: var(--block-space);
	padding-left: var(--block-space);
}

.c-block--inset-r {
	padding-right: var(--block-space);
}
