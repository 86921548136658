// Enable/Disable the utils that are being used

@use "../tools" as tools;

.u-color {
	// ex: primary-contrast, secondary-contrast
	@include tools.generate-value("contrast-low", "primary-tint", "primary-base", "secondary-shade", "secondary-tint")
		using ($value) {
		color: var(--#{$value}-color);
	}
}

// .u-bg {
// 	// ex: primary-base, secodary-base
// 	@include tools.generate-value() using ($value) {
// 		background-color: var(--#{$value}-color);
// 	}
// }
