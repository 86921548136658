@use "../tools" as tools;

$_column-gap: var(--generic-75);

// =============================================================================
// Elements
// =============================================================================
.p-shoes__grid {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-template-rows: repeat(2, 1fr);
	column-gap: var(--generic-20);
	row-gap: var(--generic-20);

	align-items: center;
}

.vertical .p-shoes__grid {
	margin-top: var(--generic-20);

	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(5, 1fr);
	column-gap: var(--generic-170);
	row-gap: var(--generic-2);

	align-items: center;
}

.p-shoes__no-shoes,
.p-shoes__grid {
	min-height: var(--generic-600);
}
