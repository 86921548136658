// Enable/Disable the utils that are being used

@use "../tools" as tools;

// =============================================================================
// font-family
// =============================================================================
.u-font-f {
	@include tools.generate-value(primary, secondary) using ($value) {
		font-family: var(--font-f-#{$value});
	}
}

// =============================================================================
// font-weight
// =============================================================================
.u-font-w {
	@include tools.generate-value(bold, medium, normal) using ($value) {
		font-weight: var(--font-w-#{$value});
	}
}

// =============================================================================
// text-align
// =============================================================================
.u-text-c {
	text-align: center;
}

.u-text-l {
	text-align: left;
}

.u-text-r {
	text-align: right;
}

// =============================================================================
// text-transform
// =============================================================================
.u-text-up {
	text-transform: uppercase;
}

// .u-text-low {
// 	text-transform: lowercase;
// }

// =============================================================================
// Whitespace
// =============================================================================
.u-text-nowrap {
	white-space: nowrap;
}

.u-text-preline {
	white-space: pre-line;
}

// =============================================================================
// position
// =============================================================================
.u-font-normalize {
	transform: translateY(1px);
}

.u-line-anywhere {
	line-break: anywhere;
}
