@use "../tools" as tools;

// =============================================================================
// Block
// =============================================================================
.c-highlight {
	position: relative;

	display: inline-block;
	padding: var(--generic-18) var(--generic-18) var(--generic-14);

	color: var(--primary-base-color);

	will-change: padding;
	transition: padding 0.5s ease-in-out; // stylelint-disable-line plugin/no-low-performance-animation-properties
}

.horizontal .highlight .c-button__inner {
	padding: var(--generic-18) var(--generic-20);
}

.vertical .not-first-slide.c-highlight:first-of-type {
	padding: var(--generic-6) var(--generic-18) 0;

	&.is-hidden {
		padding: 0;
	}
}

.s-french.vertical .not-first-slide.c-highlight:first-of-type {
	padding: var(--generic-12) var(--generic-18) var(--generic-6);

	&.is-hidden {
		padding: 0;
	}
}

.detail-slider .c-highlight {
	clip-path: inset(0% 1px 0% 0%);
}

.s-chinese .c-highlight {
	padding-top: var(--generic-12);
}

.c-highlight-recommendation {
	padding: 0 0.15vw;
}

.vertical .c-highlight-recommendation {
	padding: 0 0.1vh;
}

.c-highlight.detail-compare {
	position: relative;
	top: var(--generic--2);

	padding: var(--generic-2);
}

.c-highlight-membership,
.s-chinese .c-highlight-membership {
	position: absolute;

	padding: 0 var(--generic-5);
}

.c-highlight-membership.no-absolute {
	position: relative;
}

.c-highlight-membership .hidden,
.hidden {
	visibility: hidden;
	position: relative;
	height: 0;
}

// =============================================================================
// Elements
// =============================================================================
.c-highlight__title {
	position: relative;

	max-width: 38vw;
}

.vertical .c-highlight__title {
	max-width: 44.5vh;
}

.vertical .verticalCompare .c-highlight {
	max-width: 20vh;
}

.c-highlight__tagline {
	position: relative;
}

.c-highlight__title-mask {
	opacity: 0;
}

.c-highlight__title-codification {
	position: absolute;
	top: 50%;
	left: 0;

	transform: translateY(-50%);
}

.c-highlight-recommendation .c-highlight__title-codification {
	top: 75%;
}

.horizontal .c-highlight-recommendation .c-highlight__title-codification,
.rise .c-highlight-recommendation .c-highlight__title-codification,
.vertical .c-highlight-recommendation .c-highlight__title-codification {
	top: 52%;
}

.c-highlight-membership .c-highlight__title-codification {
	margin-top: var(--generic-1);
}

.s-korean .c-highlight__title-codification {
	top: 60%;
}

.c-highlight__desc {
	max-width: var(--generic-330);
}

.c-highlight__bg {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	background-color: var(--primary-contrast-color);

	transform-origin: left top;
	transition: background-color 0.5s ease-in-out;
}

.c-highlight__bg.rounded {
	border-radius: var(--radius-default);
}

.vertical .c-highlight-personalizing-vertical {
	padding: 0 var(--generic-3);
}

// =============================================================================
// States
// =============================================================================
.c-highlight {
	&.is-hidden {
		padding: 0;
	}
}
