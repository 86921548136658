@use "../tools" as tools;

// =============================================================================
// Block
// =============================================================================
.c-slider-nav {
	display: flex;
	justify-content: center;
	align-items: center;
}

.c-slider-nav--shoe-picker {
	display: flex;
	justify-content: end;
	align-items: end;
	width: 100%;
	padding-right: var(--generic-30);

	button {
		margin-left: var(--generic-15);
	}
}

.vertical .c-slider-nav--shoe-picker button,
.horizontal .c-slider-nav--shoe-picker button {
	box-shadow: var(--box-shadow-base);
}

.vertical .c-slider-nav--shoe-picker {
	padding-right: 0;
}

.vertical.c-modal .c-slider-nav--shoe-picker {
	padding-right: var(--generic-30);
}

// =============================================================================
// Elements
// =============================================================================
.c-slider-nav__item {
	position: relative;

	width: var(--generic-53);
	height: var(--generic-3);
	overflow: hidden;
	margin: var(--generic-5);

	border-radius: var(--radius-small);

	cursor: pointer;
}

.c-slider-nav__bg {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	opacity: 0.2;
	background-color: var(--primary-base-color);
}

.c-slider-nav__bar {
	position: absolute;
	top: 0;
	left: 0;
	z-index: var(--z-base);

	width: 100%;
	height: 100%;

	background-color: var(--primary-base-color);

	transform-origin: left center;

	&.is-hidden {
		opacity: 0;
	}
}
