@use "../tools" as tools;

// =============================================================================
// Membership Menu
// =============================================================================
.c-membership-menu {
	width: 100%;
}

.c-membership-menu .fill-color {
	background-color: var(--background-base-color);
}

.vertical .c-membership-menu .fill-color {
	// background-color: var(--background-alt-color);
}

.c-membership-menu .nike-qr-code {
	width: var(--generic-56);
}

.vertical .c-membership-menu .nike-qr-code {
	width: var(--generic-80);
}

.marquee-container {
	overflow: hidden;
	height: 100%;
}

.marquee-container .marquee {
	display: flex;
	align-items: center;
}

.vertical .c-membership-menu .identity-minimized {
	position: absolute;
	bottom: 0;
	right: 0;
}
