@use "../tools" as tools;

// =============================================================================
// Block
// =============================================================================
/**
 * 1. Relative positioning to enable placing items on top of an image
 * 2. Overflow hidden to cut off the image once a border-radius is used
 */
.c-image {
	position: relative; // 1

	overflow: hidden; // 2
}

// =============================================================================
// Elements
// =============================================================================
.c-image__visual {
	width: 100%;
	max-width: 100%;
	height: 100%;
}

.vertical .product-grouping .c-image--logo .c-image__visual {
	height: auto;
	width: 140%;
	max-width: 140%;
	margin-left: -20%;
}

// =============================================================================
// Modifiers
// =============================================================================
.c-image--contain {
	.c-image__visual {
		object-fit: contain;
		object-position: center;
		height: 100%;
	}
}

.c-image--cover {
	width: 100%;
	height: 100%;

	.c-image__visual {
		object-fit: cover;
		object-position: center;
		height: 100%;
	}
}

.c-image--cover.no-detail img {
	position: relative;
	top: var(--generic--1455);

	width: 350%;
	height: 350%;
}

.horizontal .compare-page.c-image--cover.no-detail img {
	top: 0;
	height: 100%;
	width: 100%;
}

.c-image--cover-bottom {
	width: 100%;
	height: 100%;

	.c-image__visual {
		object-fit: cover;
		object-position: bottom;
		height: 100%;
	}
}

.c-image--retina {
	.c-image__visual {
		width: 50%;
		height: 50%;
	}
}

.c-image--privacy {
	width: var(--generic-120);
	height: auto;
}

.c-image--logo {
	margin-top: -#{var(--generic-8)};
}

.vertical .product-grouping .c-image--logo {
	display: flex;
	align-items: center;
	height: 100%;
}

.vertical .c-image--no-grouping {
	display: flex;
	align-items: center;
	justify-content: center;
}

.vertical .c-image--no-grouping .c-image__visual {
	width: 300%;
	height: 300%;
	max-width: 300%;
	overflow: visible;
}
