@use "../tools" as tools;

// =============================================================================
// Elements
// =============================================================================
.c-product-style__logo {
	filter: invert(1);
}

.horizontal .c-product-style__logo {
	transform: scale(1.3);
}
