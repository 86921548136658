@use "../tools" as tools;

// =============================================================================
// Block
// =============================================================================
.c-circle-label {
	display: inline-block;
}

// =============================================================================
// Elements
// =============================================================================
.c-circle-label__text {
	writing-mode: vertical-rl;

	transform: rotate(180deg) !important; // stylelint-disable-line declaration-no-important
}
