@use "../tools" as tools;

// =============================================================================
// Block
// =============================================================================
.c-favorites span {
	padding-right: var(--generic-6);
}

.c-favorites .c-icon {
	margin-top: 1px;
}

.rise .c-favorites span {
	padding-right: var(--generic-17);
}

.c-favorites--toast {
	padding: var(--generic-11) var(--generic-15);
	position: absolute;
	background-color: black;
	border-radius: var(--radius-default);
	box-shadow: var(--box-shadow-base);
	color: white;
}

.detail .c-favorites--toast {
	bottom: var(--generic-60);
	left: var(--generic-10);
}

.compare .c-favorites--toast {
	bottom: var(--generic-54);
	right: var(--generic-8);
	left: unset;
}

.vertical .detail .c-favorites--toast {
	bottom: var(--generic-145);
	left: var(--generic-515);
}

.vertical .detail.isDigital .c-favorites--toast {
	bottom: var(--generic-145);
	left: var(--generic-602);
}

.vertical .detail.isCompareMode .c-favorites--toast {
	bottom: var(--generic-145);
	left: var(--generic-625);
}

.vertical .detail.isDigital.isCompareMode .c-favorites--toast {
	bottom: var(--generic-145);
	left: var(--generic-786);
}

.vertical .compare .c-favorites--toast {
	bottom: var(--generic-185);
	right: var(--generic-307);
	left: unset;
}

.vertical .compare.isDigital .c-favorites--toast {
	bottom: var(--generic-185);
	right: var(--generic-145);
	left: unset;
}

.s-french.vertical {
	.detail .c-favorites--toast {
		bottom: var(--generic-145);
		left: var(--generic-565);
	}

	.detail.isDigital .c-favorites--toast {
		bottom: var(--generic-145);
		left: var(--generic-709);
	}

	.detail.isCompareMode .c-favorites--toast {
		bottom: var(--generic-145);
		left: var(--generic-590);
	}

	.detail.isDigital.isCompareMode .c-favorites--toast {
		bottom: var(--generic-145);
		left: var(--generic-802);
	}

	.compare .c-favorites--toast {
		bottom: var(--generic-128);
		right: var(--generic-290);
		left: unset;
	}

	.compare.isDigital .c-favorites--toast {
		bottom: var(--generic-128);
		right: var(--generic-75);
		left: unset;
	}
}

.rise .detail .c-favorites--toast {
	bottom: var(--generic-70);
	left: var(--generic-10);
}

.rise .compare .c-favorites--toast {
	bottom: var(--generic-56);
	right: var(--generic-10);
}

.c-favorites--toast .c-icon {
	position: absolute;
	top: var(--generic-31);
	left: var(--generic-60);
}

.compare .c-favorites--toast .c-icon {
	top: var(--generic-31);
	left: unset;
	right: var(--generic-7);
}

.vertical .c-favorites .c-button__inner {
	padding: var(--generic-6) var(--generic-20) var(--generic-7);
}

.rise .c-favorites__icon-only .c-button__inner,
.horizontal .c-favorites__icon-only .c-button__inner {
	padding: var(--generic-3) var(--generic-5) var(--generic-2) var(--generic-5);
	height: 100%;
	width: 100%;
}

.vertical .c-favorites__icon-only .c-button__inner {
	padding: var(--generic-7) var(--generic-8) var(--generic-6) var(--generic-8);
	height: 100%;
	width: 100%;
}
