@use "../tools" as tools;

// =============================================================================
// Block
// =============================================================================
.c-review-stars {
	justify-content: flex-start;
	align-items: center;
}

// =============================================================================
// Elements
// =============================================================================
.c-review-stars__text {
	margin-left: var(--generic-26);
}
