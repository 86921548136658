@use "../tools" as tools;

// =============================================================================
// Elements
// =============================================================================
.index-page .info-tag {
	position: absolute;
	right: var(--generic-15);
	bottom: var(--generic-7);

	font-size: x-large;
}

.index-page .copy {
	position: absolute;
	top: 0;
	left: 0;

	color: var(--secondary-base-color);
}

.index-page .copy-tag {
	font-size: large;
}
