// Enable/Disable the utils that are being used

@use "../tools" as tools;

// =============================================================================
// flex
// =============================================================================
.u-flex {
	display: flex;
}

.u-inline-flex {
	display: inline-flex;
}

// =============================================================================
// direction
// =============================================================================
[class*="flex"] {
	&.u-row {
		flex-direction: row;
	}

	&.u-row-reverse {
		flex-direction: row-reverse;
	}

	&.u-col {
		flex-direction: column;
	}
}

// =============================================================================
// wrap
// =============================================================================
[class*="flex"] {
	&.u-wrap {
		flex-wrap: wrap;
	}

	&.u-no-wrap {
		flex-wrap: nowrap;
	}
}

// =============================================================================
// justify-content
// =============================================================================
[class*="flex"] {
	&.u-jc {
		// start, end, center, stretch, between, around, evenly
		@include tools.generate-value(end, center, between) using ($value) {
			@if ($value == start) {
				justify-content: flex-start;
			} @else if ($value == end) {
				justify-content: flex-end;
			} @else if ($value == between) {
				justify-content: space-between;
			} @else if ($value == around) {
				justify-content: space-around;
			} @else if ($value == evenly) {
				justify-content: space-evenly;
			} @else {
				justify-content: $value;
			}
		}
	}
}

// =============================================================================
// align-content
// =============================================================================
[class*="flex"] {
	&.u-ac {
		// start, end, center, stretch, between, around, evenly
		@include tools.generate-value(end) using ($value) {
			@if ($value == start) {
				align-content: flex-start;
			} @else if ($value == end) {
				align-content: flex-end;
			} @else if ($value == between) {
				align-content: space-between;
			} @else if ($value == around) {
				align-content: space-around;
			} @else if ($value == evenly) {
				align-content: space-evenly;
			} @else {
				align-content: $value;
			}
		}
	}
}

// =============================================================================
// align-items
// =============================================================================
[class*="flex"] {
	&.u-ai {
		// start, end, center, stretch, baseline
		@include tools.generate-value(start, center, end) using ($value) {
			@if ($value == start) {
				align-items: flex-start;
			} @else if ($value == end) {
				align-items: flex-end;
			} @else {
				align-items: $value;
			}
		}
	}
}

// =============================================================================
// align-self
// =============================================================================
[class*="flex"] > {
	.u-as {
		// start, end, center, stretch, baseline
		@include tools.generate-value(start, end) using ($value) {
			@if ($value == start) {
				align-self: flex-start;
			} @else if ($value == end) {
				align-self: flex-end;
			} @else {
				align-self: $value;
			}
		}
	}
}

// =============================================================================
// item
// =============================================================================
[class*="flex"] > {
	// .u-flex-item {
	// 	flex: 1 1 0%;
	// }

	// 	.u-flex-auto {
	// 		flex: 1 1 auto;
	// 	}

	// 	.u-flex-initial {
	// 		flex: 0 1 auto;
	// 	}

	// 	.u-flex-none {
	// 		flex: none;
	// 	}

	.u-flex {
		// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
		@include tools.generate-value(1, 2) using ($value) {
			flex: $value;
		}
	}

	.u-flex-grow {
		// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
		@include tools.generate-value(1, 2, 3, 5) using ($value) {
			flex-grow: $value;
		}
	}

	// 	.u-flex-shrink {
	// 		// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
	// 		@include tools.generate-value() using ($value) {
	// 			flex-shrink: $value;
	// 		}
	// 	}
}
