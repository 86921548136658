@use "../tools" as tools;

// =============================================================================
// Block
// =============================================================================
.c-filter-dropdown {
	position: relative;

	display: inline-flex;
	flex-direction: column;

	border-radius: var(--radius-large);
	box-shadow: var(--box-shadow-base);
}

// =============================================================================
// Elements
// =============================================================================
.c-filter-dropdown__flyout {
	position: absolute;
	bottom: 50%;
	left: 0;
	z-index: 0;

	// In px because minus vw doesn't work.
	width: calc(100% + 20px);
	max-height: var(--generic-680);
	overflow: hidden;
	margin: -10px -10px 0;
	padding: 10px 10px 0;
}

.vertical .c-filter-dropdown__flyout {
	padding: 0 10px 10px;
}

.vertical .c-filter-dropdown__flyout {
	top: 65%;
	bottom: unset;

	z-index: 1;
}

.c-filter-dropdown__items {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: var(--generic-24) 0 var(--generic-40);

	background-color: var(--background-alt-color);
	box-shadow: var(--box-shadow-base);
	border-top-left-radius: var(--radius-large);
	border-top-right-radius: var(--radius-large);
}

.vertical .c-filter-dropdown__items {
	padding: var(--generic-40) 0 var(--generic-24);

	border-top-left-radius: unset;
	border-top-right-radius: unset;
	border-bottom-left-radius: var(--radius-large);
	border-bottom-right-radius: var(--radius-large);

	background-color: var(--background-alt-color);
	box-shadow: var(--box-shadow-x) var(--box-shadow-y) var(--box-shadow-blur) var(--shadow-color);
}

.c-filter-dropdown__item {
	padding: var(--generic-10) var(--generic-23);
}

.c-filter-dropdown__trigger {
	position: relative;
	z-index: 1;

	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	min-width: var(--generic-170);
	padding: var(--generic-10) var(--generic-23);

	background-color: var(--background-alt-color);
	border-radius: var(--radius-large);
}

.c-filter-dropdown__icon-wrapper {
	margin-left: var(--generic-16);
}

.c-filter-dropdown__icon {
	transform: translateY(-14%) rotate(90deg);
}
