@use "../tools" as tools;

// =============================================================================
// Block
// =============================================================================
.c-button {
	display: inline-block;
	width: fit-content;

	text-align: center;

	border: var(--border-width) var(--border-style) var(--primary-base-color);
	border-radius: var(--radius-default);
	background-color: var(--primary-base-color);
	color: var(--primary-contrast-color);

	will-change: width;

	&:focus-visible {
		outline: none;
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-button__inner {
	display: inline-flex;
	align-items: center;
	padding: var(--generic-11) var(--generic-20);
}

.place .c-button--inverse .c-button__inner {
	padding: var(--generic-13) var(--generic-20);
}

.horizontal .c-button__inner {
	padding: var(--generic-7) var(--generic-20);
}

.horizontal .c-button--large .c-button__inner {
	padding: var(--generic-10) var(--generic-20);
}

.s-korean.horizontal .c-button--large .c-button__inner {
	padding: var(--generic-10) var(--generic-20) var(--generic-9);
}

.horizontal .favorite .c-button__inner {
	padding: var(--generic-7) var(--generic-16);
}

.horizontal .place .c-button__inner {
	padding: var(--generic-10) var(--generic-30);
}

.horizontal .place .c-button--large .c-button__inner {
	padding: var(--generic-12) var(--generic-30);
}

.vertical .c-button__inner {
	padding: var(--generic-12) var(--generic-20) var(--generic-11);
}

.s-french.vertical .c-button__inner {
	padding: var(--generic-12) var(--generic-10) var(--generic-11);
}

.vertical .place .c-button--large .c-button__inner {
	padding: var(--generic-13) var(--generic-20);
}

.vertical .detail .c-button__inner {
	padding: var(--generic-12) var(--generic-20) var(--generic-11);
}

.vertical .highlight .c-button__inner {
	padding: var(--generic-15) var(--generic-18);
}

.s-korean .c-button__inner {
	padding: 0.6vw 1vw 0.45vw;
}

// =============================================================================
// Modifiers
// =============================================================================
.c-button--rounded {
	border-radius: var(--radius-max);
}

.c-button--secondary {
	background-color: var(--secondary-shade-color);
	color: var(--secondary-contrast-color);
}

.c-button--error {
	background-color: var(--error-base-color);
}

.c-button--outline {
	background-color: transparent;
	border: var(--border-width) var(--border-style) var(--primary-base-color);
	color: var(--primary-base-color);

	&.c-button--secondary {
		color: var(--secondary-contrast-color);
	}
}

.c-button--inverse {
	border: var(--border-width) var(--border-style) var(--primary-contrast-color);
	background-color: var(--primary-contrast-color);
	color: var(--primary-base-color);
}

.c-button--ghost {
	padding: 0;

	background-color: transparent;
	color: var(--primary-base-color);
	border-color: transparent;

	&.c-button--secondary {
		color: var(--secondary-shade-color);
	}
}

.c-button--float {
	box-shadow: var(--box-shadow-base);
}

.c-button.highlight {
	border-radius: 0;
}

// =============================================================================
// States
// =============================================================================
.c-button {
	&.is-disabled {
		pointer-events: none;
	}
}
