@use "../tools" as tools;

// =============================================================================
// Block
// =============================================================================
// .c-review {}

// =============================================================================
// Elements
// =============================================================================
.c-review__description {
	max-width: var(--generic-348);
}

.vertical .c-review__description {
	max-width: var(--generic-445);
}
