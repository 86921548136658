@use "../tools" as tools;

// =============================================================================
// Block
// =============================================================================
.c-display {
	position: absolute;

	width: 100vw;
	height: 100vh;

	background-color: #212121;
}

// =============================================================================
// Elements
// =============================================================================
.c-display__container {
	position: relative;

	width: 100vw;
	height: 56.25vw;
	overflow: hidden;

	background-color: var(--background-base-color);
}

.vertical .c-display__container {
	width: 56.25vh;
	height: 100vh;
}

/**
 * This color is only used for debugging, and therefore should not be linked to
 * the color palette.
 */
.c-display__offscreen {
	display: flex;
	flex: 1;
	height: 100%;

	background-color: rgba(0, 0, 0, 0.4); // 1
}
