@use "../tools" as tools;

// =============================================================================
// Block
// =============================================================================
.c-status {
	display: flex;
	align-items: center;
}

// =============================================================================
// Elements
// =============================================================================
.c-status__label {
	margin-left: var(--generic-8);

	color: var(--primary-base-color);
}

.c-status__icon {
	width: var(--generic-6);
	height: var(--generic-6);

	border-radius: var(--radius-default);
	background-color: var(--success-base-color);
}

// =============================================================================
// Modifiers
// =============================================================================
.c-status--warning {
	.c-status__icon {
		background-color: var(--warning-base-color);
	}
}

.c-status--error {
	.c-status__icon {
		background-color: var(--error-base-color);
	}
}
