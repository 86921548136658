// Enable/Disable the utils that are being used

// =============================================================================
// border
// =============================================================================
// .u-border {
// 	border: var(--border-base);
// }

.u-border-t {
	border-top: var(--border-base);
}

// .u-border-b {
// 	border-bottom: var(--border-base);
// }

// .u-border-l {
// 	border-left: var(--border-base);
// }

// .u-border-r {
// 	border-right: var(--border-base);
// }

// .u-border-y {
// 	border-top: var(--border-base);
// 	border-bottom: var(--border-base);
// }

// .u-border-x {
// 	border-left: var(--border-base);
// 	border-right: var(--border-base);
// }

// =============================================================================
// divider
// =============================================================================
// .u-divider-x {
// 	& > * + * {
// 		border-left: var(--border-base);
// 	}
// }

.u-divider-y {
	& > * + * {
		border-top: var(--border-base);
	}
}

// =============================================================================
// radius
// =============================================================================
// .u-radius {
// 	border-radius: var(--radius-default);
// }

// .u-radius-max {
// 	border-radius: var(--radius-max);
// }

// .u-radius-tl-max {
// 	border-top-left-radius: var(--radius-max);
// }

// .u-radius-tr-max {
// 	border-top-right-radius: var(--radius-max);
// }

// .u-radius-bl-max {
// 	border-bottom-left-radius: var(--radius-max);
// }

// .u-radius-br-max {
// 	border-bottom-right-radius: var(--radius-max);
// }

// .u-radius-round {
// 	border-radius: var(--radius-round);
// }

// .u-radius-tl-round {
// 	border-top-left-radius: var(--radius-round);
// }

// .u-radius-tr-round {
// 	border-top-right-radius: var(--radius-round);
// }

// .u-radius-bl-round {
// 	border-bottom-left-radius: var(--radius-round);
// }

// .u-radius-br-round {
// 	border-bottom-right-radius: var(--radius-round);
// }
