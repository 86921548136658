@use "../tools" as tools;

// =============================================================================
// Block
// =============================================================================
.c-grid-block {
	position: absolute;
}

.vertical .c-grid-block.off-color {
	background-color: var(--background-base-color);
}
