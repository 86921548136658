@use "../tools" as tools;

// =============================================================================
// Block
// =============================================================================
.c-shoe-preview {
	display: flex;
	flex-direction: column;

	text-decoration: none;

	user-select: none;
	-webkit-user-drag: none;
}

.horizontal .c-shoe-preview--outline {
	border-radius: 5px;
	border-style: solid;
	border-color: var(--secondary-shade-color);
	border-width: 2px;

	height: 13vw;
	width: 11.5vw;
	position: absolute;

	margin-left: -0.7vw;
}

.vertical .c-shoe-preview--outline {
	border-radius: 5px;
	border-style: solid;
	border-color: var(--secondary-shade-color);
	border-width: 2px;

	height: 13vh;
	width: 11.5vh;
	position: absolute;

	margin-left: -0.7vh;
	margin-top: 0.5vh;
}

.c-shoe-preview--container {
	max-width: 13vw;
	min-height: 10vw;
	padding: 0 var(--generic-10);
}

.vertical .c-shoe-preview--container {
	max-width: 13vh;
	min-height: 14.5vh;
}

// =============================================================================
// Elements
// =============================================================================
.c-shoe-preview__image_container {
	width: var(--generic-200);
}

.c-shoe-preview__image {
	object-fit: contain;
	object-position: center;
	height: 100%;
}

.c-shoe-preview__title {
	color: var(--primary-tint-color);
}

// =============================================================================
// Modifiers
// =============================================================================
.c-shoe-preview__image--fallback {
	background-color: var(--background-alt-color);
}

.c-shoe-preview--selected {
	border-radius: 5px;
	border-style: solid;
	border-color: var(--secondary-shade-color);
	border-width: 2px;
}

.horizontal .c-shoe-preview--selected,
.vertical .c-shoe-preview--selected {
	border-width: 0px;
}
