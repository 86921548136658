@use "../tools" as tools;

// =============================================================================
// Block
// =============================================================================
.c-codification {
	position: relative;
}

// =============================================================================
// Elements
// =============================================================================
.c-codification__word {
	display: inline-block;
}

.c-codification__word.extend {
	min-width: max-content;
}

.c-codification__character {
	position: relative;
}

.c-codification__result {
	opacity: 0;
}

.c-codification__glyph {
	position: absolute;
	top: 50%;
	left: 50%;

	display: flex;
	justify-content: center;
	align-items: center;

	transform: translateX(-50%) translateY(-50%);
}

// =============================================================================
// Modifiers
// =============================================================================
.c-codification--right {
	text-align: right;
}
