/**
 * 1. Ensure the page always fills up the entire height of the viewport.
 * 2. Hide scrollbars because we should never be able to scroll the UI.
 * 3. Makes sure that 1rem = 16px, all rem scale along this percentage. Use
 *    _system.rem(16px) to output rem correctly.
 */
:root {
	min-height: 100%; // 1
	overflow: hidden; // 2

	font-size: 100%; // 3
}

/**
 * 1. Fix for 100vh issue on safari mobile browsers, use min-height 100vh as
 *    fallback
 */
body {
	min-height: 100vh; // 1

	font-family: var(--font-f-secondary);
	font-weight: var(--font-w-normal);
	font-size: var(--font-s-default);
	line-height: var(--line-height-default);

	color: var(--contrast-high);
}
